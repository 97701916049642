import { render, staticRenderFns } from "./UserLevel.vue?vue&type=template&id=88251962&"
import script from "./UserLevel.vue?vue&type=script&lang=js&"
export * from "./UserLevel.vue?vue&type=script&lang=js&"
import style0 from "./UserLevel.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBtn,VCol,VDataTable,VRow,VTextField,VTooltip,VTreeview})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/-36BVGaF/0/applicationg/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('88251962')) {
      api.createRecord('88251962', component.options)
    } else {
      api.reload('88251962', component.options)
    }
    module.hot.accept("./UserLevel.vue?vue&type=template&id=88251962&", function () {
      api.rerender('88251962', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/User/UserLevel.vue"
export default component.exports